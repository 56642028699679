.SubscriptionsLanding .lander {
  padding: 0px 0;
}

.SubscriptionsLanding .lander h1 {
  font-weight: 600;
}

.SubscriptionsLanding .lander p {
  color: #999;
}

.SubscriptionsLanding .subRow {
  padding-top: 8px;
}
