.Home .lander {
  padding: 0px 0;
}

.Home .lander h1 {
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}
