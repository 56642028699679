.PhotoLanding .lander {
  padding: 0px 0;
}

.PhotoLanding .lander h1 {
  font-weight: 600;
}

.PhotoLanding .lander p {
  color: #999;
}
