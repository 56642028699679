.Login .lander {
  padding: 0px 0;
}

.Login .lander h1 {
  font-weight: 600;
}

.Login .lander p {
  color: #999;
}
