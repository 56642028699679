.CreatorLanding .lander {
  padding: 0px 0;
}

.CreatorLanding .lander h1 {
  font-weight: 600;
}

.CreatorLanding .lander p {
  color: #999;
}
