.App {
  font-family: Futura,Helvetica,Trebuchet MS,Arial,sans-serif;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.padded-row {
  margin-bottom: 15px;
}

.padded-row-big {
  margin-bottom: 30px;
}

.just-right {
  text-align: right;
}

.light {
  color: #404040;
}

.code-light {
  color: #404040;
}

.text-over-image {

  background-color: black;
  color: #fff;
  display: inline;
  padding: 0.5rem;


  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
